import React from 'react';
import './SecondSection.css';

const SecondSection = () => {
  return (
    <div className="second-section" id="second-section">
      <div className="scrolling-banner">
        <div className="scrolling-text">
        (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  (◠‿◠✿) uwu (◠‿◠✿)  </div>
      </div>
      <h2 className="section-title">Where the bitches at?</h2>
      <div className="family-container">

      </div>
    </div>
  );
};

export default SecondSection;
