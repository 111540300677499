import React from 'react';
import './Footer.css';
import telegramIcon from './tg.png';
import twitterIcon from './twitter.png';
import left from './left.png';
import right from './right.png';


const Footer = () => {
  return (
    <div className="footer">
      <h2 className="footer-title">SOCIALS</h2>
      <p className="footer-subtitle">JOIN THE $TGIRLS COMMUNITY</p>
      <div className="footer-buttons">
        <button className="footer-button buy-button">Buy $TGIRLS</button>
        <button className="footer-button dex-button">DEX Tools</button>
      </div>
      <div className="footer-social-icons">
        <a href="https://t.me/trongirls" target="_blank" rel="noopener noreferrer">
          <img src={telegramIcon} alt="Telegram" className="social-icon" />
        </a>
        <a href="https://x.com/TronGirls" target="_blank" rel="noopener noreferrer">
          <img src={twitterIcon} alt="Twitter" className="social-icon" />
        </a>
      </div>
      <img src={left} alt="Left Goose" className="background-image left-goose" />
      <img src={right} alt="Right Goose" className="background-image right-goose" />
    </div>
  );
};

export default Footer;
