import React from 'react';
import './App.css';
import MainSection from './MainSection';
import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection';
import FourthSection from './FourthSection';
import Footer from './Footer';

function App() {
  return (
    <div className="App">
      <MainSection id="main-section" />
      <SecondSection id="second-section" />
      <ThirdSection id="third-section" />
      <FourthSection id="fourth-section" />
      <Footer /> 
    </div>
  );
}

export default App;
