import React from 'react';
import './FourthSection.css';

const FourthSection = () => {
  return (
    <div className="fourth-section" id="fourth-section">
      <h2 className="section-title">How to buy</h2>
      <div className="card-container">
        <div className="card">
          <div className="card-number">01</div>
          <div className="card-content">
            <h3>DOWNLOAD TRONLINK</h3>
            <p>Download & install the TronLink Wallet either from the app store on your phone or the browser extension for desktop.</p>
          </div>
        </div>
        <div className="card">
          <div className="card-number">02</div>
          <div className="card-content">
            <h3>LOAD UP TRX</h3>
            <p>Head over to your favorite exchange, choose how much TRX you would like to deposit on your wallet.</p>
          </div>
        </div>
        <div className="card">
          <div className="card-number">03</div>
          <div className="card-content">
            <h3>BUY $TGIRLS</h3>
            <p>Head over to sunpump.meme and swap your TRX for $TGIRLS.</p>
          </div>
        </div>
        <div className="card">
          <div className="card-number">04</div>
          <div className="card-content">
            <h3>ADD $TGIRLS TO YOUR WALLET</h3>
            <p>Now all you have to do is add the $TGIRLS contract address to your TronLink Wallet for your $TGIRLS tokens to show. Welcome aboard!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourthSection;
