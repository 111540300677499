import React from 'react';
import './ThirdSection.css';

const ThirdSection = () => {
  return (
    <div className="third-section" id="third-section">
      <h2 className="section-title">Tron Girls</h2>
      <div className="family-container">

      </div>
    </div>
  );
};

export default ThirdSection;
