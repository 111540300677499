import React, { useState } from 'react';
import './MainSection.css';
import iconClose from './twitter.png'; 
import iconMinimize from './tg.png'; 
import goosyImage from './1.png'; 

const MainSection = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="main-container" id="main-section">
      <nav className="navbar">
        <div className="nav-links">
          <a href="#main-section" className="nav-link"></a>
          <a href="#second-section" className="nav-link"></a>
          <a href="#third-section" className="nav-link"></a>
          <a href="#fourth-section" className="nav-link"></a>
        </div>
        <div className="top-right-icons">
          <div className="burger-menu" onClick={toggleMenu}>
            <div className="burger-bar"></div>
            <div className="burger-bar"></div>
            <div className="burger-bar"></div>
          </div>
          <a href="https://t.me/trongirls" target="_blank" rel="noopener noreferrer">
            <img src={iconMinimize} alt="Telegram" className="icon" />
          </a>
          <a href="https://x.com/TronGirls" target="_blank" rel="noopener noreferrer">
            <img src={iconClose} alt="Twitter" className="icon" />
          </a>
          <button className="buy-now-button">BUY NOW</button>
        </div>
      </nav>
      <div className="content-container">
        <div className="text-container">
          <h1>TRON GIRLS</h1>
          <p>(◠‿◠✿) uwu (◠‿◠✿)</p>
          <div className="button-container">
            <button className="action-button">BUY NOW</button>
            <a href="https://t.me/trongirls" target="_blank" rel="noopener noreferrer" className="action-button-link">
              <button className="action-button">JOIN US!</button>
            </a>
          </div>
        </div>
        <div className="image-container">
          <img src={goosyImage} alt="Goosy" className="bear-image" />
        </div>
      </div>
    </div>
  );
};

export default MainSection;
